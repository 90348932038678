<template>
  <div>
    <el-table :data="ListData" stripe style="width: 100%" v-loading="tabelLoading">
      <el-table-column label="序号" width="55" fixed align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="窗口" width="150" fixed align="center" show-overflow-tooltip>
        <template slot-scope="scope"> 窗口{{ scope.$index + 1 }} </template>
      </el-table-column>
      <el-table-column prop="deviceName" label="设备名称" fixed align="center" show-overflow-tooltip></el-table-column>
      <el-table-column prop="orgName" label="所属项目部" align="center" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="updateTime" label="更新时间" align="center" show-overflow-tooltip> </el-table-column>
      <el-table-column label="操作" fixed="right" width="230" align="center">
        <template slot-scope="{ row }">
          <span class="action-btn" v-if="$check('on-wall_add')" @click="handleOnwall(row)" style="margin-right:16px">上墙设置</span>
          <span class="action-btn" v-if="$check('on-wall_edit')" @click="handleCancel(row.id)">取消上墙</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 上传履职记录dialog -->
    <el-dialog
      title="上墙设置"
      :visible.sync="visibleDialog"
      width="590px"
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <el-form :model="ruleForm" ref="ruleForm" label-width="120px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="关联摄像头" required="">
              <TreeSelect
                :data="CameraTree"
                :defaultProps="defaultProps"
                nodeKey="id"
                :checkedKeys="defaultCheckedKeys"
                @popoverHide="popoverHide"
              >
              </TreeSelect>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { windowList, getCameraTree, onDisplaySetUp } from 'api/general-user/visualization/on-wall';
import TreeSelect from '@/components/treeSelect/index.vue';

export default {
  components: { TreeSelect },
  data() {
    return {
      tabelLoading: false,
      visibleDialog: false,
      btnLoading: false,
      ListData: [],
      ruleForm: {},
      //查看详情数据
      defaultCheckedKeys: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      CameraTree: []
    };
  },
  methods: {
    handleOnwall(row) {
      this.ruleForm.windowId = row.id;
      this.defaultCheckedKeys = [row.deviceId];
      this.visibleDialog = true;
    },
    handleCancel(id) {
      this.$confirm('请确认是否取消上墙该设备', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        this.ruleForm.windowId = id;
        this.ruleForm.deviceId = '';
        onDisplaySetUp(this.ruleForm)
          .then(res => {
            this.$message.success('取消上墙成功');
            this.getListPage();
          })
          .catch(() => {
            this.btnLoading = false;
          });
      });
    },
    handleConfirm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.ruleForm.deviceId) {
            this.onDisplaySetUp();
            this.ruleForm = {};
            this.defaultCheckedKeys = [];
          } else {
            this.$message.error('请选择设备！');
          }
        }
      });
    },
    //新增履职记录Api
    onDisplaySetUp() {
      this.btnLoading = true;
      onDisplaySetUp(this.ruleForm)
        .then(res => {
          this.$message.success('上墙成功');
          this.visibleDialog = false;
          this.btnLoading = false;
          this.getListPage();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    dialogClose() {
      this.visibleDialog = false;
      this.ruleForm = {};
      this.defaultCheckedKeys = [];
      this.$refs.ruleForm.resetFields();
    },
    //获取交建下的所有机构摄像头
    getCameraTree() {
      getCameraTree().then(res => {
        this.CameraTree = res.data;
      });
    },
    //列表
    getListPage() {
      this.tabelLoading = true;
      windowList()
        .then(res => {
          this.tabelLoading = false;
          this.ListData = res.data;
        })
        .catch(() => {
          this.tabelLoading = false;
        });
    },
    // 选择上级分类
    //TODO
    popoverHide(checkedIds, checkedData) {
      if(!checkedData.isDevice){
        this.ruleForm.deviceId = checkedIds;
      }else{
        this.$message.error("请选择设备！")
      }
      // this.checkedData = checkedData
    }
  },
  created() {
    this.getListPage();
    this.getCameraTree();
  }
};
</script>
