import request from '@/utils/request.js';
/**
 * 上墙设置
 * @returns {Promise}
 */
export function onDisplaySetUp(data) {
  return request({
    url: '/traffic-construction/cameraWindowDisplay/onDisplaySetUp',
    method: 'post',
    data
  });
}

/**
 * 窗口列表
 * @returns {Promise}
 */
 export function windowList(params) {
  return request({
    url: '/traffic-construction/cameraWindowDisplay/windowList',
    method: 'get',
    params
  });
}


/**
 * 上墙记录
 * @returns {Promise}
 */
 export function onDisplayRecord(params) {
  return request({
    url: '/traffic-construction/cameraWindowDisplay/onDisplayRecord',
    method: 'get',
    params
  });
}

/**
 * 查询摄像机表列表
 * @returns {Promise}
 */
 export function getCameraTree(params) {
  return request({
    url: '/traffic-construction/device/list',
    method: 'get',
    params
  });
}